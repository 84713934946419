import React, { useEffect, useState } from 'react';
import { DashboardContainer, DashboardContent, HorizontalLineSmall } from '../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RetailerHeaderDashboard } from '../Header/Header';
import { getTerminalsForRetailer, getTerminalsForStoreManager } from '../../services/terminalService';
import CreateTerminal from './CreateTerminal';
import { Redirect } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#B5B5B5',
    color: theme.palette.common.white,
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const useStyles = makeStyles((theme) => ({
  typographyLarge: {
    fontFamily: 'Gotham-Rounded-Book !important',
    color: '#A0D2BB',
    textAlign: 'right'
  },
  typographySmall: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textAlign: 'left',
    textTransform: 'uppercase',
    letterSpacing: '4px !important',
    marginLeft: '5px !important'
  },
  welcomeMessage: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    marginTop: '-40px'
  },
  label: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  offersContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  offer: {
    flex: '1 0 32%',
    margin: '5px',
    height: '200px',
    border: '1px solid black',
    display: 'flex'
  },
  tableContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
  }
}));

const Terminals = () => {
  const classes = useStyles();
  const [terminals, setTerminals] = useState([]);
  const [showMessage, setShowMessage] = useState(true);

  const currentUser = useSelector((state) => state.users.currentUser);

  useEffect(() => {
    if (currentUser.role === 'retail_admin' || currentUser.role === 'retail_user') {
      getTerminalsForRetailer(currentUser.retailer_id, currentUser.token).then((response) => {
        setTerminals(response.data);
      });
    } else if (currentUser.role === 'store_admin' || currentUser.role === 'store_user') {
      getTerminalsForStoreManager(currentUser.uid, currentUser.token).then((response) => {
        setTerminals(response.data);
      });
    }
    setTimeout(() => {
      setShowMessage(false)
    }, 2000);
  }, [currentUser.retailer_id, currentUser.role, currentUser.token, currentUser.uid])

  return (
    <>
      {
        (currentUser.retailer_id) ? (
          <>
            <RetailerHeaderDashboard />
            <HorizontalLineSmall center color='#426A6D' thin width='80%' />
            <DashboardContainer>
              <DashboardContent>
                <div className={classes.welcomeMessage}>
                  <Typography variant='h6' className={classes.typographyLarge}>
                    Hi {currentUser.firstName}, welcome back
                  </Typography>
                </div>
                <br />
                <div className={classes.label}>
                  <Typography variant='body2' className={classes.typographySmall}>
                    Terminals
                  </Typography>
                  <CreateTerminal />
                </div>
                {
                  (terminals.length === 0) ? (
                    <div>
                      <Typography className={classes.typography} hidden={showMessage} variant='h4'>
                        You don't have any terminals yet.
                      </Typography>
                      <Typography hidden={!showMessage} className={classes.typography} variant='h4'>
                        Loading...
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.tableContainer}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Pseudo name</StyledTableCell>
                              <StyledTableCell align="right">Acquirer ID</StyledTableCell>
                              <StyledTableCell align="right">Gateway ID</StyledTableCell>
                              <StyledTableCell align="right">Store ID</StyledTableCell>
                              <StyledTableCell align="right">SN</StyledTableCell>
                              <StyledTableCell align="right">Actions</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {terminals.map((row) => (
                            <StyledTableRow key={row.serialNumber}>
                              <StyledTableCell component="th" scope="row">
                                {row.pseudoName}
                              </StyledTableCell>
                              <StyledTableCell align="right">{row.acquirer_terminal_ID}</StyledTableCell>
                              <StyledTableCell align="right">{row.gateway_terminal_ID}</StyledTableCell>
                              <StyledTableCell align="right">{row.s_id}</StyledTableCell>
                              <StyledTableCell align="right">{row.serialNumber}</StyledTableCell>
                              <StyledTableCell align="right">
                                <CreateTerminal edit={true} terminal={row} />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )
                }
              </DashboardContent>
            </DashboardContainer>
          </>
        ) : (
          <Redirect to='/' />
        )
      }
    </>
  )
}

export default Terminals;
