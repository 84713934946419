/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
} from "@material-ui/core";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { RetailerDrawerComponent, ConsumerDrawerComponent } from './Drawer';
import logo from '../../images/logo_big.png';
import logoGreen from '../../images/logo_green.png';
import Scroll from 'react-scroll';
import { app } from '../../firebase';
import AddUser from '../Dashboard/AddUser';
import { getRetailerLogo } from '../../services/retailerService';

const scroller = Scroll.scroller;
const retailerView = window.location.href.includes('/retailer-dashboard') || window.location.href.includes('/offers') || window.location.href.includes('/transactions') || window.location.href.includes('/insights') || window.location.href.includes('/stores');

const useStyles = makeStyles((theme) => ({
  navlinksRetailer: {
    display: 'flex',
    position: 'absolute',
    right: '100px',
    bottom: '0'
  },
  navlinks: {
    display: 'flex',
    position: 'absolute',
    right: '300px',
  },
 logo: {
    cursor: "pointer",
    width: '75px',
  },
  retailerViewNav: {
    background: '#fafafa !important',
    color: '#000000',
    height: '100px',    
  },
  linkRetailer: {
    textDecoration: "none",
    color: '#A0D2BB',
    fontSize: '15px',
    marginLeft: theme.spacing(4),
    fontFamily: 'Gotham-Rounded-Bold !important',
    textTransform: 'uppercase',
    marginTop: '5px',
    borderBottom: '4px solid transparent',
    '&:hover': {
      borderBottom: '4px solid #426A6D',
      color: '#426A6D',
    },
    '&:active': {
      borderBottom: '4px solid #426A6D',
      color: '#426A6D'
    }
  },
  andLabel: {
    textDecoration: "none",
    color: '#426A6D',
    fontSize: '22px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  link: {
    textDecoration: "none",
    color: '#FFFFFF',
    fontSize: '15px',
    marginLeft: theme.spacing(4),
    fontFamily: 'Gotham-Rounded-Bold !important',
    textTransform: 'lowercase',
    marginTop: '5px',
    borderBottom: '4px solid transparent',
    '&:hover': {
      borderBottom: '4px solid yellow',
      color: '#ffffff',
    },
    '&:active': {
      borderBottom: '4px solid #426A6D',
      color: '#426A6D'
    }
  },
}));

export const RetailerHeaderDashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [scrollYPosition, setScrollPositionY] = useState(0);
  const currentUser = useSelector((state) => state.users.currentUser);
  const [logo, setLogo] = useState('');


  const signOut = () => {
    app.auth().signOut();
  };

  window.addEventListener('scroll', (e) => {
    const lastKnownPosition = window.scrollY;
    setScrollPositionY(lastKnownPosition);
  });

  useEffect(() => {
    getRetailerLogo(currentUser.retailer_id, currentUser.token).then((response) => {
      setLogo(response.data.logo);
    })
  }, [currentUser.retailer_id, currentUser.token]);

  return (
    <AppBar className={classes.retailerViewNav} elevation={0} position="sticky" style={{ background: scrollYPosition > 0 ? 'linear-gradient(to right, rgb(127, 203, 174), rgb(77, 255, 184))' : 'transparent' }}>
        <CssBaseline />
            <Toolbar>
              <div style={{ display: 'flex', paddingTop: '10px' }}>
                <img src={logoGreen} alt="my-pop-logo" className={classes.logo} onClick={() => retailerView ? window.location.href='/' : window.location.href='/consumer'} />
                <Typography className={classes.andLabel} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  &
                </Typography>
                <img src={logo} alt="retailer-logo" className={classes.logo} />
              </div>
              {isMobile ? (
                <RetailerDrawerComponent />
              ) : (
                <div className={classes.navlinksRetailer}> 
                  <AddUser />
                  <Link
                    to="/retailer-dashboard" 
                    className={classes.linkRetailer}>
                    Dashboard
                  </Link>
                  <Link
                    to="/transactions" 
                    className={classes.linkRetailer}>
                    Transactions
                  </Link>
                  <Link
                    to="/stores"
                    className={classes.linkRetailer}>
                    Stores
                  </Link>    
                  <Link
                    to="/terminals"
                    className={classes.linkRetailer}>
                    Terminals
                  </Link>    
                  <Link
                    to="/insights"
                    className={classes.linkRetailer}>
                    Insights
                  </Link>    
                  <Link
                    to="/offers"
                    className={classes.linkRetailer}>
                    Offers
                  </Link>
                  <Link
                    to="/"
                    className={classes.linkRetailer}
                    onClick={() => signOut()}
                  >
                    Log out
                  </Link>
                </div>
              )}
            </Toolbar>
      </AppBar>
  )
}

export const RetailerHeaderHome = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [scrollYPosition, setScrollPositionY] = useState(0);

  window.addEventListener('scroll', (e) => {
    const lastKnownPosition = window.scrollY;
    setScrollPositionY(lastKnownPosition);
  });

  return(
    <AppBar elevation={0} position="sticky" style={{ background: scrollYPosition > 0 ? 'linear-gradient(to right, rgb(127, 203, 174), rgb(77, 255, 184))' : 'transparent' }}>
      <CssBaseline />
          <Toolbar>
            <img src={logo} alt="logo" className={classes.logo} />
            {isMobile ? (
              <RetailerDrawerComponent />
            ) : (
              <div className={classes.navlinks}>
                <Link to="/" onClick={() => scroller.scrollTo('home', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: 0,
                  })}
                  className={classes.link}>
                  Home
                </Link>
                <Link to="/" onClick={() => scroller.scrollTo('features', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  })}
                  className={classes.link}>
                  Features
                </Link>
                <Link to="/" onClick={() => scroller.scrollTo('how-it-works', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: 0,
                  })}
                  className={classes.link}>
                  How it works
                </Link>
                <Link to="/" onClick={() => scroller.scrollTo('benefits', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  })}
                  className={classes.link}>
                  Benefits
                </Link>
                <Link to="/" onClick={() => scroller.scrollTo('testemonials', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: 0,
                  })}
                  className={classes.link}>
                  Testemonials
                </Link>
                <Link to="/" onClick={() => scroller.scrollTo('our-partners', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  })}
                  className={classes.link}>
                  Our partners
                </Link>
                <Link to="/" onClick={() => scroller.scrollTo('contact-us', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: 0,
                  })}
                  className={classes.link}>
                  Contact us
                </Link>
                <Link 
                  onClick={() => window.location.href = '/consumer'}
                  className={classes.link}>
                  Consumer portal
                </Link>
              </div>
            )}
          </Toolbar>
    </AppBar>
  );
};

export const ConsumerHeaderHome = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [scrollYPosition, setScrollPositionY] = useState(0);

  window.addEventListener('scroll', (e) => {
    const lastKnownPosition = window.scrollY;
    setScrollPositionY(lastKnownPosition);
  });

  return(
    <AppBar elevation={0} position="sticky" style={{ background: scrollYPosition > 0 ? 'linear-gradient(to right, rgb(127, 203, 174), rgb(77, 255, 184))' : 'transparent' }}>
      <CssBaseline />
          <Toolbar>
            <img src={logo} alt="logo" className={classes.logo} />
            {isMobile ? (
              <ConsumerDrawerComponent />
            ) : (
              <div className={classes.navlinks}>
                <Link to="/consumer" onClick={() => scroller.scrollTo('consumer-home', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: 0,
                  })}
                  className={classes.link}>
                  Home
                </Link>
                <Link to="/consumer" onClick={() => scroller.scrollTo('consumer-how-it-works', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: 0,
                  })}
                  className={classes.link}>
                  How it works
                </Link>
                <Link to="/consumer" onClick={() => scroller.scrollTo('consumer-sign-up', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  })}
                  className={classes.link}>
                  Sign up
                </Link>
                <Link
                  onClick={() => window.location.href = '/'}
                  className={classes.link}>
                  Retailer portal
                </Link>
              </div>
            )}
          </Toolbar>
    </AppBar>
  );
};

export const ConsumerHeaderDashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [scrollYPosition, setScrollPositionY] = useState(0);

  const signOut = () => {
    app.auth().signOut();
  };

  window.addEventListener('scroll', (e) => {
    const lastKnownPosition = window.scrollY;
    setScrollPositionY(lastKnownPosition);
  });

  return (
    <AppBar className={classes.retailerViewNav} elevation={0} position="sticky" style={{ background: scrollYPosition > 0 ? 'linear-gradient(to right, rgb(127, 203, 174), rgb(77, 255, 184))' : 'transparent' }}>
        <CssBaseline />
            <Toolbar>
              <img src={logoGreen} alt="my-pop-logo" className={classes.logo} />
              {isMobile ? (
                <RetailerDrawerComponent />
              ) : (
                <div className={classes.navlinksRetailer}>
                  <Link
                    to="/consumer-account" 
                    className={classes.linkRetailer}>
                    My account
                  </Link>
                  <Link
                    to="/consumer-transactions" 
                    className={classes.linkRetailer}>
                    My transactions
                  </Link>
                  <Link
                    to="/upload-receipt" 
                    className={classes.linkRetailer}>
                    Upload receipt
                  </Link>
                  <Link
                    to="/consumer"
                    className={classes.linkRetailer}
                    onClick={() => signOut()}
                  >
                    Log out
                  </Link>
                </div>
              )}
            </Toolbar>
      </AppBar>
  )
};
