import React, { forwardRef, useEffect, useState } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { app } from '../../firebase';
import { CustomButton, CustomInput, HorizontalLineSmall, CustomSelect, LoginBoxOverflown, CustomButtonSmall, SocialButton, HorizontalLineBig} from '../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { retailerAdminSignUp, updateLogo } from '../../services/retailerService';
import Collapse from '@mui/material/Collapse';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import Alert from '@mui/material/Alert';
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { consumerSignUp } from '../../services/userService';
import { Login } from './Login';
import ReactQuill from 'react-quill';
import Resizer from "react-image-file-resizer";


const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  typographyUnderlined: {
    fontFamily: 'Gotham-Rounded-Light !important',
    textDecoration: 'underline',
  }
});

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const consumerView = window.location.href.includes('/consumer');

const Registration = ({ smallButton }) => {
  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyBrandName, setCompanyBrandName] = useState('');
  const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [numOfEmployees, setNumOfEmployees] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState ('');
  const [password, setPassword] = useState('');
  const [confrimPassword, setConfirmPassword] = useState('');
  const [accept, setAccept] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState(<span />);
  const [showLogin, setShowLogin] = useState(false);
  const [terms, setTerms] = useState('');
  const [logo, setLogo] = useState('');

  const classes = useStyles();

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      70,
      70,
      "png",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      70,
      70
    );
  });

  const signInWithProvider = (provider) => {
    app.auth().signInWithPopup(provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const {user} = result;
        console.log(user);
      }).catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const {email} = error.customData;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log(error);
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  };

  const handleCompanyBrandName = (e) => {
    setCompanyBrandName(e.target.value);
  };

  const handleCompanyRegistrationNumber = (e) => {
    setCompanyRegistrationNumber(e.target.value);
  };

  const handleVatNumber = (e) => {
    setVatNumber(e.target.value);
  };

  const handleNumOfEmployees = (e) => {
    setNumOfEmployees(e.target.value);
  }

  const handleCountry = (e) => {
    setCountry(e.target.value);
  };
  
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleAddress1 = (e) => {
    setAddress1(e.target.value);
  };

  const handleAddress2 = (e) => {
    setAddress2(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  }

  const handleAccept = (e) => {
    setAccept(e.target.checked);
  };

  const handleLogoChnage = async (e) => {
    const image = await resizeFile(e.target.files[0]);
    setLogo(image);
  };

  const clearFields = () => {
    setAddress1('');
    setAddress2('');
    setCompanyBrandName('');
    setCountry('');
    setEmail('');
    setFirstName('');
    setLastName('');
    setCompanyName('');
    setNumOfEmployees('');
    setPhoneNumber('');
    setCompanyRegistrationNumber('');
    setVatNumber('');
    setPassword('');
    setConfirmPassword('');
    setTerms('');
    setLogo('');
  }

  const handleRegister = async() => {
    if (consumerView) {
      await consumerSignUp({
        data: {
          firstName,
          lastName,
          email,
          password,
          isSocial: false,
        }
      }).then(() => {
        setSeverity('success');
        setMessage('Registration sucessfull!');
        setShowAlert(true);
        clearFields();
        setTimeout(() => {
          setShowLogin(true);
        }, 2000);
      }).catch ((err) => {
        setSeverity('error');
        setMessage(err.response.data);
        setShowAlert(true);
      });
    } else {
      await retailerAdminSignUp({ 
        data: {
          r_addressLine1: address1,
          r_addressLine2: address2,
          r_brandName: companyBrandName,
          r_cdate: new Date(),
          r_country: country,
          r_email: email,
          r_firstName: firstName,
          r_lastName: lastName,
          r_name: companyName,
          r_numberOfEmployees: numOfEmployees,
          r_phoneNumber: phoneNumber,
          r_registrationNumber: companyRegistrationNumber,
          r_vatNumber: vatNumber,
          r_termsHtml: terms,
          r_termsPlainText: terms.replace(/<\/?[^>]+(>|$)/g, ""),
        },
        
      }).then(() => {
        app.storage().ref(`retailer-logos/${logo.name}`).put(logo).then((snapshot) => {
          snapshot.ref.getDownloadURL().then(async (url) => {
            await updateLogo({
              data: {
                email,
                url,
              }
            }).catch((err) => {
              setSeverity('error');
              setMessage(err.response.data);
              setShowAlert(true);
            });
          });
        })
      }).then(() => {
        setSeverity('success');
        setMessage('Registration sucessfull! Please check your email!');
        setShowAlert(true);
        clearFields();
        setTimeout(() => {
          setShowLogin(true);
        }, 2000);
      }).catch((err) => {
        setSeverity('error');
        setMessage(err.response.data);
        setShowAlert(true);
      });
    }
  }

  useEffect(() => {
    showAlert ? setAlert(
      <Collapse in={showAlert}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    ) : setAlert(
      <span />
    )
  }, [showAlert, message, severity]);
  
  return (
    <div>
      {
        smallButton ? (
          <CustomButtonSmall style={{
            marginLeft: '20px'
          }}
          onClick={handleClickOpen}>
            Register
          </CustomButtonSmall>
        ) : (
          <CustomButton style={{
            marginLeft: '20px'
          }}
          onClick={handleClickOpen}>
            Register
          </CustomButton>
        )
      }
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            textAlign: 'center',
          }
        }}
      >
        <LoginBoxOverflown>
          {alert}
          {
            (showLogin) ? (
              <Login show={showLogin} />
            ) : (
              <span />
            )
          }
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: 'absolute',
              right: '0',
              color: '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
          {
            consumerView ? (
              <Typography className={classes.typography} variant='body1'>
                MY-POP SIGNUP
              </Typography>
            ) : (
              <Typography className={classes.typography} variant='body1'>
                MY-POP RETAILER REGISTRATION
              </Typography>
            )
          }
          <HorizontalLineSmall center />
          <br />
          <Typography variant='h4' className={classes.typography}>
            Registration
          </Typography>
          <br />
          <br />
          <CustomInput
            placeholder='Company Name *'
            aria-label='Company Name'
            value={companyName}
            onChange={handleCompanyName}
            hidden={consumerView}
          />
          <br hidden={consumerView} />
          <CustomInput
            placeholder='Company Brand Name'
            aria-label='Company Brand Name'
            value={companyBrandName}
            onChange={handleCompanyBrandName}
            hidden={consumerView}
          />
          <br hidden={consumerView} />
          <CustomInput
            placeholder='Company Registration Number'
            aria-label='Company Registration Number'
            value={companyRegistrationNumber}
            onChange={handleCompanyRegistrationNumber}
            hidden={consumerView}
          />
          <br hidden={consumerView} />
          <CustomInput
            placeholder='VAT Number'
            aria-label='VAT Number'
            value={vatNumber}
            onChange={handleVatNumber}
            hidden={consumerView}
          />
          <br hidden={consumerView} />
          <CustomSelect
            placeholder='Number of Employees *'
            aria-label='Number of Employees'
            value={numOfEmployees}
            onChange={handleNumOfEmployees}
            hidden={consumerView}
          >
            <option value="" disabled defaultValue="">Number of employees *</option>
            <option value="1-5">1-5</option>
            <option value="6-19">6-19</option>
            <option value="17-99">17-99</option>
            <option value="100-420">100-420</option>
            <option value="421-2499">421-2499</option>
            <option value="2499+">2499+</option>
          </CustomSelect>
          <br hidden={consumerView} />
          <CustomSelect
            placeholder='Country *'
            aria-label='Country'
            value={country}
            onChange={handleCountry}
            hidden={consumerView}
          >
            <option value="" disabled defaultValue="">Select country *</option>
            <option value="United Kingdom">United Kingdom</option>
          </CustomSelect>
          <br hidden={consumerView} />
          <CustomInput
            placeholder='First Name *'
            aria-label='First Name'
            value={firstName}
            onChange={handleFirstName}
          />
          <br />
          <CustomInput
            placeholder='Last Name *'
            aria-label='Last Name'
            value={lastName}
            onChange={handleLastName}
          />
          <br />
          <CustomInput
            hidden={consumerView}
            placeholder='Phone Number *'
            aria-label='Phone Number'
            value={phoneNumber}
            onChange={handlePhoneNumber}
          />
          <br hidden={consumerView} />
          <CustomInput
            placeholder='Email *'
            aria-label='Email'
            value={email}
            onChange={handleEmail}
          />
          <br />
          <CustomInput
            hidden={consumerView}
            placeholder='Address Line 1 *'
            aria-label='Address Line 1'
            value={address1}
            onChange={handleAddress1}
          />
          <CustomInput
            hidden={!consumerView}
            placeholder='Password *'
            aria-label='Password'
            value={password}
            type="password"
            onChange={handlePassword}
          />
          <br />
          <CustomInput
            hidden={consumerView}
            placeholder='Address Line 2'
            aria-label='Address Line 2'
            value={address2}
            onChange={handleAddress2}
          />
          <CustomInput
            hidden={!consumerView}
            placeholder='Confirm password *'
            aria-label='Confirm password'
            value={confrimPassword}
            type="password"
            onChange={handleConfirmPassword}
          />
          <br />
          <Typography variant='body1' style={{
            textAlign: 'left',
            marginLeft: '12px',
            fontFamily: 'Gotham-Rounded-Book'
            }}
            hidden={consumerView}
          >
            <label for="accept"> Upload your logo *</label>
          </Typography>
          <CustomInput hidden={consumerView} type="file" accept="image/*" onChange={handleLogoChnage} />
          {consumerView ? (
            <span />
          ) : (
            <ReactQuill hidden={consumerView} style={{
              backgroundColor: "#FFFFFF",
              color: '#000000',
              overflowX : 'scroll',
              height: '300px'
            }} theme="snow" value={terms} onChange={setTerms} />
          )}
          <br />
          <input type="checkbox" checked={accept} onChange={handleAccept} />
          <Typography variant='caption'>
            <label for="accept">&nbsp; &nbsp; I accept My-POP terms & condtions</label>
          </Typography>
          <br />
          <br />
          <CustomButton variant='contained' onClick={() => handleRegister()}>
            Register
          </CustomButton>
          <br />
          <br />
          <div>
          <HorizontalLineBig hidden={!consumerView} />
            <br />
            <SocialButton hidden={!consumerView} variant='contained' onClick={() => signInWithProvider(googleProvider)}>
              <GoogleIcon />
              Login with Google
            </SocialButton>
            <br />
            <br />
            <SocialButton hidden={!consumerView} variant='contained' onClick={() => signInWithProvider(facebookProvider)}>
              <FacebookIcon />
              Login with Facebook
            </SocialButton>
            <br />
            <br />
            <SocialButton hidden={!consumerView} variant='contained' onClick={() => signInWithProvider(appleProvider)}>
              <AppleIcon />
              Login with Apple
            </SocialButton>
          </div>
        </LoginBoxOverflown>
      </Dialog>
    </div>
  )
}

export default Registration;
