import React from 'react';
import { useSelector } from 'react-redux';
import {
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { app } from '../../firebase';
import Scroll from'react-scroll';
import { CustomButton, HomeContainer, HomeContext, HorizontalLineSmall } from '../index.styled';
import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Registration from '../Authentication/Registration';
import Login from '../Authentication/Login';

const Element  = Scroll.Element;

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  typographyUnderlined: {
    fontFamily: 'Gotham-Rounded-Light !important',
    textDecoration: 'underline',
  }
});

export const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const currentUser = useSelector((state) => state.users.currentUser);

  const signOut = () => {
    app.auth().signOut();
  };

  return (
    <Element name="home">
      <HomeContainer isMobile={isMobile}>
        <HomeContext isMobile={isMobile}>
          <HorizontalLineSmall />
          <Typography variant='h4' className={classes.typography}>
            My-POP.
          </Typography>
          <Typography variant='h4' className={classes.typography}>
            Digital Receipting and Loyalty Offers for your business and your customers .
          </Typography>
          <HorizontalLineSmall />
          <div style={{
            display: 'flex',
          }}>
            {
              (currentUser?.uid && currentUser?.retailer_id) ? (
                <>
                  <CustomButton onClick={() => signOut()}>
                    Log out
                  </CustomButton>
                  <CustomButton onClick={() => window.location.href='/retailer-dashboard'} style={{ marginLeft: '20px' }}>
                    Dashboard
                  </CustomButton>
                </>
              ) : (
                <>
                  <Login />
                  <Registration />
                </>
              )
            }
          </div>
        </HomeContext>
      </HomeContainer>
    </Element>
  )
}

export default Home;
